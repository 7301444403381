import React from "react";
import "./meetTheBand.scss";
import topLeft from "../../photos/flowers/meet__topLeft.svg";
import topRight from "../../photos/flowers/meet__topRight.svg";
import midRight from "../../photos/flowers/meet__midRight.svg";
import Carousel from "../../components/memberCarousel/memberCarousel";

export default function meetTheBand() {
  return (
    <div className="meet__bigBox">
      <div className="meet" id="collective">
        <img
          src={topLeft}
          alt="decorative flower"
          className="meet__flowers--topLeft"
        ></img>

        <div>
          <div className="meet__title--box">
            <h4 className="meet__title">Meet the Collective</h4>
            <p className="meet__para">
              Jaleo Flamenco is comprised of flamenco artists and aficionados
              from diverse backgrounds who come together to learn, practice,
              perform, and share flamenco with the community.
            </p>
          </div>
          <Carousel />
        </div>
        <div>
          <div>
            <img
              src={topRight}
              alt="decorative leaves"
              className="meet__flowers--topRight"
            ></img>
          </div>
          <img
            src={midRight}
            alt="decorative flowers"
            className="meet__flowers--midRight"
          ></img>
        </div>
      </div>
    </div>
  );
}
