import Hamburger from "./Hamburger2.jsx";
import { useState } from "react";
import "./Header2.scss";
import mobileLogo from "../../photos/logo/mobileLogo.svg";
import logo from "../../photos/logo/Logo 3.svg";
import facebook from "../../photos/icons/facebook.svg";
import insta from "../../photos/icons/insta.svg";

export default function Header2() {
  const handleClickHome = () => {
    if (window.document.location.pathname === "/") {
      window.scrollTo(0, 0);
    }
  };

  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen);
  };
  const closeHamburger = () => {
    setHamburgerOpen(false);
  };

  const handleClickScroll = () => {
    const element = document.getElementById("collective");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    closeHamburger();
  };

  const scrollToEvents = () => {
    const events = document.getElementById("events");
    if (events) {
      events.scrollIntoView({ behavior: "smooth" });
    }
    closeHamburger();
  };

  const scrollToContact = () => {
    const events = document.getElementById("contact");
    if (events) {
      events.scrollIntoView({ behavior: "smooth" });
    }
    closeHamburger();
  };
  const goToTop = () => {
    const events = document.getElementById("top");
    if (events) {
      events.scrollIntoView({ behavior: "smooth" });
    }
    closeHamburger();
  };

  return (
    <div>
      <div className="navigation">
        <div className="navigation__bigbox">
          <div onClick={goToTop} className="header-logo">
            <img src={logo} className="coqui-header-logo" alt="logo" />
            <img
              src={mobileLogo}
              alt="Jaleo Flamenco"
              className="navigation__logo--mobile"
            />
          </div>

          <ul>
            <div to="/" className="nav-link nav-link--home" onClick={goToTop}>
              Home
            </div>
            <div to="/" className="nav-link" onClick={handleClickScroll}>
              The Collective
            </div>
            <div to="/services" className="nav-link" onClick={scrollToEvents}>
              Events
            </div>
            <a href="#contact" className="nav-link" onClick={scrollToContact}>
              Contact
            </a>
          </ul>
          <div className="navigation__social">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/jaleoflamenconola"
            >
              <img
                src={facebook}
                alt="facebook link"
                className="navigation__social--facebook"
              ></img>
            </a>

            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/jaleoflamenconola/"
            >
              <img src={insta} alt="Instagram link"></img>
            </a>
          </div>
          <div className="header-logo-box">
            <div onClick={handleClickHome} className="header-logo-1">
              <img src={mobileLogo} alt="Jaleo Flamenco" />
            </div>

            <div className="hamburger" onClick={toggleHamburger}>
              <Hamburger isOpen={hamburgerOpen} />
            </div>
          </div>
        </div>
      </div>

      <style jsx="true">{`
        .navigation ul {
          display: flex;
          flex-wrap: nowrap;

          width: 100vw;
          padding: 0px;
          overflow: hidden;
          z-index: 10;
          left: 0px;
        }
        .navigation ul Link {
          list-style-type: none;
          padding-right: 10px;
        }
        .hamburger {
          display: none;
          z-index: 6;
        }
        @media (max-width: 1511px) {
          .hamburger {
            display: initial !important;
            padding-top: 7px;
            margin-left: 10px;
            z-index: 6;
          }

          .navigation ul {
            display: ${hamburgerOpen ? "flex" : "none"};
            background-color: #121212;
            height: 100vh;
            flex-direction: column;
            align-items: center;
            width: 100vw;
            margin-top: 93px;
            margin-right: 200px;
            position: fixed;
          }
        }
      `}</style>
    </div>
  );
}
