import React from "react";
import "./footer.scss";
import leftFlower from "../../photos/flowers/footer__left.svg";
import rightFlower from "../../photos/flowers/footer__right.svg";
import facebook from "../../photos/icons/facebook.svg";
import insta from "../../photos/icons/insta.svg";

export default function footer() {
  return (
    <div className="footer__box">
      <div className="footer">
        <img
          src={leftFlower}
          alt="decorative flower with stem"
          className="footer__leftFlower"
        ></img>
        <div className="footer__textBox">
          <p className="footer__creds">
            Photo credits: <br></br>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/peacockeyes/"
            >
              @peacockeyes
            </a>
            <br></br>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/taylordecluephotography/"
            >
              @taylordecluephotography
            </a>
          </p>
          <div className="footer__social">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/jaleoflamenconola"
            >
              <img
                src={facebook}
                alt="facebook link"
                className="footer__social--facebook"
              ></img>
            </a>

            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/jaleoflamenconola/"
            >
              <img src={insta} alt="Instagram link"></img>
            </a>
          </div>
        </div>
        <img
          src={rightFlower}
          alt="decorative flowers"
          className="footer__rightFlower"
        ></img>
      </div>
    </div>
  );
}
