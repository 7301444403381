import React from "react";
import "./contact.scss";
import fb_icon from "../../photos/icons/fb-purple.svg";
import ig_icon from "../../photos/icons/ig-purple.svg";
import ContactForm from "../ContactForm/ContactForm";
import leftFlower from "../../photos/flowers/contact__topLeft.svg";
import rightFlower from "../../photos/flowers/contact__right.svg";

export default function Contact() {
  return (
    <div className="contact__biggestBox">
      <div className="contact" id="contact">
        <img
          src={leftFlower}
          alt="Decorative branch"
          className="contact__leftFlower"
        ></img>
        <div className="contact__bigBox">
          <div className="contact__background">
            <div className="contact__box">
              <h1 className="contact__h1">Get in touch with us</h1>
              <p className="contact__para">
                We'd love to hear from you! Jaleo Flamenco is available for
                public or private performances, classes, demonstrations, and
                more! Join our email list and follow us on Instagram and
                Facebook to stay informed of our upcoming events.
              </p>
              <div className="contact__container">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.facebook.com/jaleoflamenconola"
                >
                  <img
                    src={fb_icon}
                    alt="facebook icon"
                    className="contact__textIcon"
                  />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.instagram.com/jaleoflamenconola/"
                >
                  <img
                    src={ig_icon}
                    alt="instagram icon"
                    className="contact__emailIcon"
                  />
                </a>
              </div>
            </div>
          </div>
          <ContactForm />
        </div>
        <img
          src={rightFlower}
          alt="decorative roses"
          className="contact__rightFlower"
        ></img>
      </div>
    </div>
  );
}
