import "./App.scss";
import { BrowserRouter as Router } from "react-router-dom";
// import Header from "./components/header/Header.jsx";
import Hero from "./components/hero/hero.jsx";
// import Events from "./components/events/events.jsx";
import MeetTheBand from "./components/meetTheBand/meetTheBand.jsx";
import Contact from "./components/contact/contact.jsx";
import Footer from "./components/footer/footer.jsx";
import Header2 from "./components/Header2/Header2";

function App() {
  return (
    <div className="App">
      <Router>
        <div className="app__box">
          {/* <Header /> */}
          <Header2 />
          <Hero />
          {/* <Events /> */}
          <MeetTheBand />
          <Contact />
          <Footer />
        </div>
      </Router>
    </div>
  );
}

export default App;
