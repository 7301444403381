import { Swiper, SwiperSlide } from "swiper/react";
import {
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
} from "swiper/modules";
import "./membercarousel.scss";
// Import Swiper styles
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import "swiper/scss/scrollbar";
import daniella from "../../photos/members/daniella.jpg";
import ingrid from "../../photos/members/ingrid.JPG";
import greg from "../../photos/members/greg.jpeg";
import corina from "../../photos/members/corina.jpeg";
import miguel from "../../photos/members/miguel.jpeg";
import cienna from "../../photos/members/Cienna.JPEG";

export default function meetTheBand() {
  return (
    <Swiper
      modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
      centeredSlides={true}
      slidesPerView={1}
      breakpoints={{
        1511: {
          slidesPerView: 1,
        },
        1512: {
          slidesPerView: 3,
        },
      }}
      autoplay={{
        delay: 3500,
        disableOnInteraction: false,
      }}
      // pagination={true}
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log("slide change")}
      speed={2000}
      loop={true}
      className="carousel__box"
    >
      <SwiperSlide>
        <div className="carousel">
          <img
            src={ingrid}
            alt="Ingrid Adrianza from Jaleo Flamenco"
            className="carousel__member"
          ></img>
          <h5 className="carousel__title">Ingrid Adrianza</h5>
          <h6 className="carousel__subTitle">Baile</h6>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="carousel">
          <img
            src={daniella}
            alt="Daniella Santoro from Jaleo Flamenco"
            className="carousel__member"
          ></img>
          <h5 className="carousel__title">Daniella Santoro</h5>
          <h6 className="carousel__subTitle">Baile</h6>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="carousel">
          <img
            src={corina}
            alt="Corina Hernandez from Jaleo Flamenco"
            className="carousel__member"
          ></img>
          <h5 className="carousel__title">Corina Hernández</h5>
          <h6 className="carousel__subTitle">Cante</h6>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="carousel">
          <img
            src={cienna}
            alt="Cienna Chavez from Jaleo Flamenco"
            className="carousel__member"
          ></img>
          <h5 className="carousel__title">Cienna Chavez</h5>
          <h6 className="carousel__subTitle">Baile</h6>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="carousel">
          <img
            src={miguel}
            alt="Miguel Cruz from Jaleo Flamenco"
            className="carousel__member"
          ></img>
          <h5 className="carousel__title">Miguel Cruz</h5>
          <h6 className="carousel__subTitle">Percusión</h6>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="carousel">
          <img
            src={greg}
            alt="Greg Northwood from Jaleo Flamenco"
            className="carousel__member"
          ></img>
          <h5 className="carousel__title">Greg Northwood “El Tornaíto”</h5>
          <h6 className="carousel__subTitle">Guitarra</h6>
        </div>
      </SwiperSlide>
    </Swiper>
  );
}
